
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { beforeRouteEnter, beforeRouteUpdate } from './AuditView.before-router'
import CustomDialog from '@/components/CustomDialog/CustomDialog.vue'
import ChecklistViewer from '@/components/ChecklistBuilder/Viewer/ChecklistViewer.vue'
import { Audit } from '@/components/ChecklistBuilder/Misc/audit.interface'
import { ImportSource } from '@/components/ChecklistBuilder/Misc/import-source.enum'
import { NotificationPayload } from '@/store/notifications/notification-payload.interface'
import { getService, onReady } from '@/helpers/feathers'
import { removeOfflineAudit } from '@/helpers/audits/remove-offline-audit'
import { convertOfflineAudit } from '@/helpers/audits/convert-offline-audit'
import { Connection } from '@/offline/connection.enum'
import { OfflineEntry } from '@/offline/offline-entry.interface'
import moment from 'moment'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'

const NotificationStore = namespace('notification')
const ConnectivityStore = namespace('connectivity')

/**
 * View für die Auflistung aller Online und Offline Audits.
 */
@Component({
  components: {
    CustomDialog,
    ChecklistViewer,
    BaseFrame
  },
  beforeRouteEnter: beforeRouteEnter,
  beforeRouteUpdate: beforeRouteUpdate
})
export default class AuditView extends Vue {
  /**
   * Information über den Verbindungsstatus, ob eplas online ist.
   */
  @ConnectivityStore.Getter('online')
  public online!: boolean

  /**
   * Der Typ des Audits, welches Ausgegeben werden soll.
   */
  @Prop({
    validator: (value): boolean => value === 'online' || value === 'offline',
    required: true
  })
  public usedContent!: 'online' | 'offline'

  /**
   * ID des Audits, welches Angezeigt werden soll.
   */
  @Prop({ type: Number, required: true })
  public entryID!: number

  /**
   * ID der Checkliste-Kollection. Wird nur verwendet, wenn ein neues Audit
   * erstellt werden soll.
   */
  @Prop({ type: Number, required: false, default: 0 })
  public collectionID?: number

  /**
   * Der komplette Audit-Eintrag
   */
  public entry: Audit | null = null

  /**
   * Die Sprache die von der Checkliste verwendet werden soll.
   */
  public language = 'en'

  /**
   * Ob gerade Inhalte geladen bzw. gespeichert wird.
   */
  public isLoading = true

  /**
   * Sprachstring der Fehlermeldung, wenn das Laden des Audit-Eintrages
   * fehlgeschlagen ist
   */
  public errorMessage = 'auditView.error.notFound'

  /**
   * Alle Werte und Einstellungen für den Dialog für die Konvertierung der
   * Offline-Audits zu einem Online-Audit. Die Steuerung erfolgt über die
   * Methode [[this.onConvertOfflineAudit]].
   */
  public dlgConvertOfflineAudit = {
    display: false,
    step: 'start',
    color: 'info',
    auditID: 0
  }

  /**
   * Alle Werte und Einstellungen vom Dialog für das Löschen eines
   * Offline-Audits.
   */
  public dlgRemoveOfflineAudit = {
    display: false
  }

  /**
   * Vue-Hook 'created'. Lädt die Audit-Informationen beim ersten Aufruf der
   * Komponente.
   */
  public created(): void {
    this.loadEntry()

    this.language = this.getLanguage()
  }

  /**
   * Vue-Hook 'beforeUpdate'. Prüft ob die Sprache des Benutzers verändert
   * wurde. Ist dies der Fall, wird die neue Sprache der Checkliste mitgegeben.
   */
  public beforeUpdate(): void {
    const lang = this.getLanguage()

    if (this.language !== lang) {
      this.language = lang
    }
  }

  /**
   * Ob der geladene Audit ein Offline-Audit ist und dieser zu einem
   * Online-Audit konvertiert werden darf.
   *
   * @returns Ob der Audit zu einem Online-Audit konvertiert werden darf.
   */
  public get allowConvertOfflineAudit(): boolean {
    return (
      this.online &&
      !this.isLoading &&
      this.entry !== null &&
      this.usedContent === 'offline'
    )
  }

  /**
   * Überprüft, ob das geladene Audit ein Offline-Audit ist und dieses
   * gelöscht werden darf.
   *
   * @returns Ob das Offline-Audit gelöscht werden darf.
   */
  public get allowRemoveOfflineAudit(): boolean {
    return (
      !this.isLoading && this.entry !== null && this.usedContent === 'offline'
    )
  }

  /**
   * Gibt den Basic-Selector von allen Audits zurück.
   *
   * @returns Der Basic-Selector
   */
  public get basicSelector(): string {
    return 'audit'
  }

  /**
   * Gibt den Selector vom aktuellen Audit zurück
   *
   * @returns Der Basic-Selector
   */
  public get auditSelector(): string {
    return `${this.basicSelector}_${this.entryID}`
  }

  /**
   * Togglet einen Toaster und zeigt diesen an
   */
  @NotificationStore.Action('toggleToast')
  public toggleToast!: (payload: NotificationPayload) => void

  /**
   * Lädt die Audit-Informationen neu, wenn sich [[this.entryID]] ändert.
   *
   * @param entryID - ID des neuen Audits
   */
  @Watch('entryID')
  public onEntryChange(entryID: number): void {
    this.loadEntry(entryID)
  }

  /**
   * Lädt die Audit-Informationen neu, wenn sich [[this.usedContent]] ändert.
   *
   * @param usedContent - Typ des neuen Audits.
   */
  @Watch('usedContent')
  public onContentChange(usedContent: string): void {
    this.loadEntry(this.entryID, usedContent)
  }

  /**
   * Gibt die Einstellungen für die Checkliste zurück, die von den Standard-
   * Einstellungen abweichen.
   *
   * @returns Checklist-Einstellungs-Objekt
   */
  public get checklistSettings(): object {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      VALUE_EVENT_TEMPLATES: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        convert_offline_audit: {
          // eslint-disable-next-line require-await
          callback: async (): Promise<void> => {
            await this.directlyConvertOfflineAudit()
          }
        }
      }
    }
  }

  /**
   * Gibt zurück, ob der Benutzer die Checkliste in Deutsch oder Englisch
   * angezeigt bekommt.
   *
   * @returns - Sprache des Benutzers
   */
  public getLanguage(): string {
    return this.$i18n.locale.toLowerCase() !== 'de' ? 'en' : 'de'
  }

  /**
   * Lädt die Informationen des angegebenen Audits.
   *
   * @param entryID - ID des Audits, Fallback: [[this.entryID]]
   * @param usedContent - Typ des Audits, Fallback: [[this.usedContent]]
   */
  public loadEntry(entryID?: number, usedContent?: string): void {
    const id = entryID || this.entryID
    this.errorMessage = 'auditView.error.notFound'

    if ((usedContent || this.usedContent) !== 'online') {
      if (id !== 0) {
        this.loadOfflineEntry(id)
      } else {
        this.createOfflineEntry().catch((): void => {})
      }
    } else {
      window.location.assign(`/index.php/9/audit/edit/${id}`)
    }
  }

  /**
   * Lädt die Informationen eines Offline-Audits.
   *
   * @param entryID - ID des Audits, dessen Informationen geladen werden soll.
   */
  public async loadOfflineEntry(entryID: number): Promise<void> {
    this.isLoading = true

    await new Promise<void>((success): void => {
      onReady(success)
    })
    const service = await getService<Audit>(
      'audits/offline',
      Connection.Offline
    )
    const userID = this.$store.getters['user/id']
    await service
      .get(entryID)
      .then(
        (audit): void => {
          this.entry = audit.createdBy === userID ? audit : null
        },
        (): void => {
          this.entry = null
        }
      )
      .then((): void => {
        this.isLoading = false
      })

    if (this.entry) {
      let patch = false
      if (!this.entry.hasOwnProperty('responsibleID')) {
        this.entry.responsibleID = null
        patch = true
      }
      if (!this.entry.hasOwnProperty('executorID')) {
        this.entry.executorID = null
        patch = true
      }
      if (!this.entry.hasOwnProperty('checklistLocationID')) {
        this.entry.checklistLocationID = null
        patch = true
      }
      if (patch) {
        await service.patch(entryID, this.entry)
      }
    }
  }

  /**
   * Erstellt ein neues Offline-Audit, hierfür wird die Checklist-Collection-ID
   * verwendet, die über das Property [[this.collectionID]] angegeben werden
   * muss.
   */
  public async createOfflineEntry(): Promise<void> {
    const collectionID = this.collectionID

    if (!collectionID || collectionID <= 0) {
      this.errorMessage = 'auditView.error.missingCollectionID'
      this.isLoading = false
    } else {
      const [serviceCollection, serviceAudit, serviceEmployee] =
        await Promise.all([
          getService<{ [key: string]: unknown }>('checklists-collections/deep'),
          getService<Audit>('audits/offline', Connection.Offline),
          getService<OfflineEntry>('offline-data/employees'),
          new Promise<void>((success): void => {
            onReady(success)
          })
        ])

      const [collection, currentUser] = await Promise.all([
        serviceCollection.get(collectionID).catch((): null => null),
        serviceEmployee
          .get(this.$store.getters['user/id'])
          .catch((): null => null)
      ])

      if (
        collection === null ||
        currentUser === null ||
        typeof currentUser.id !== 'number'
      ) {
        this.errorMessage = 'auditView.error.missingCollectionID'
        this.isLoading = false
      } else {
        const categoryID: number =
          typeof collection.category === 'number' &&
          Number.isInteger(collection.category) &&
          collection.category > 0
            ? collection.category
            : 0

        const collectionLabel: string =
          typeof collection.label === 'string' && collection.label
            ? collection.label
            : '#{collectionID}'

        const userName: string[] = []
        if (typeof currentUser.firstName === 'string') {
          userName.push(currentUser.firstName)
        }
        if (typeof currentUser.lastName === 'string') {
          userName.push(currentUser.lastName)
        }
        if (typeof currentUser.personnelNumber === 'string') {
          userName.push(`[${currentUser.personnelNumber}]`)
        }

        const now: Date = new Date(Date.now())
        const nowAsString = moment(now).format('YYYY.MM.DD')

        const audit: Audit = {
          labelPrefix: '',
          label: `${collectionLabel}, ${nowAsString}, ${userName.join(' ')}`,
          labelSuffix: '',
          due: now,
          interval: 0,
          done: false,
          responsibleID: null,
          executorID: null,
          participants: [],
          externals: '',
          createdFrom: 4, // benötigt enum?
          linkID: 0,
          checklists: [collectionID],
          // completedAt: null,
          // completedBy: null,
          inactive: false,
          affectedEmployeeID: currentUser.id,
          categoryID: categoryID,
          mandatory: false,
          anonymous: false,
          // cloneOfID: null,
          createdBy: currentUser.id,
          createdAt: now,
          progressDone: 0,
          progressTotal: 0,
          checklistLocationID: null,
          importSource: ImportSource.Offline,
          importDate: now
        }

        const createdAudit = await serviceAudit
          .create(audit)
          .catch((): null => null)

        if (createdAudit !== null && createdAudit.id && createdAudit.id > 0) {
          this.$router.push({
            name: 'audit_view',
            params: {
              content: 'offline',
              id: createdAudit.id.toString()
            }
          })
        } else {
          this.errorMessage = 'auditView.error.createdFailed'
          this.isLoading = false
        }
      }
    }
  }

  /**
   * **Startet direkt eine Konvertierung von aktuellen Offline-Audits zu einem
   * Online-Audit.**
   *
   * Hierfür wird der Dialog von der Methode [[this.onConvertOfflineAudit]]
   * verwendet und entsprechend eingestellt. Sollte sich das System im Offline-
   * Modus befinden, wird eine entsprechende Fehlermeldung ausgegeben und
   * die Konvertierung abgebrochen.
   */
  protected async directlyConvertOfflineAudit(): Promise<void> {
    if (this.online) {
      const dlgData = this.dlgConvertOfflineAudit

      // Den Export-Dialog einstellen
      dlgData.color = 'primary'
      dlgData.display = true
      dlgData.auditID = 0
      dlgData.step = 'working'

      // Die Konvertierung durchführen
      await this.onConvertOfflineAudit('start_import')
    } else {
      // Information ausgeben, dass die Konvertierung von Audits nicht im
      // Offline-Modus funktioniert.
      this.toggleToast({
        type: 'error',
        text: this.$t(
          'auditView.notification.error.convertOfflineAudit'
        ).toString()
      })
    }
  }

  /**
   * Steuert den Dialog für die Konvertierung des Offline-Audits zu einem
   * Online-Audit.
   *
   * @param fnc - Welcher Step/Funktion ausgefürt werden soll.
   */
  public async onConvertOfflineAudit(
    fnc: 'show_dialog' | 'start_import' | 'btn_negative' | 'btn_positive'
  ): Promise<void> {
    const dlgData = this.dlgConvertOfflineAudit

    if (fnc === 'show_dialog') {
      dlgData.color = 'primary'
      dlgData.step = 'start'
      dlgData.auditID = 0
      dlgData.display = true
    } else if (fnc === 'start_import') {
      if (dlgData.step === 'working') {
        let offlineAudit = this.entry
        if (!offlineAudit || typeof offlineAudit.id !== 'number') {
          dlgData.color = 'error'
          dlgData.step = 'failed'
          return
        }
        const auditID = offlineAudit.id
        const service = await getService<Audit>(
          'audits/offline',
          Connection.Offline
        )
        await service.get(auditID).then(
          (audit): void => {
            offlineAudit = audit
          },
          (): void => {
            offlineAudit = null
          }
        )

        if (!this.allowConvertOfflineAudit || offlineAudit === null) {
          dlgData.color = 'error'
          dlgData.step = 'failed'
        } else {
          convertOfflineAudit(this.basicSelector, offlineAudit).then(
            (result): void => {
              dlgData.color = 'success'
              dlgData.auditID = result.id || 0
              dlgData.step = 'completed'
            },
            (): void => {
              dlgData.color = 'error'
              dlgData.step = 'failed'
            }
          )
        }
      }
    } else if (fnc === 'btn_negative') {
      if (dlgData.step === 'start' || dlgData.step === 'failed') {
        dlgData.display = false
      } else if (dlgData.step === 'completed') {
        this.$router.push({
          name: 'audit_list',
          params: { content: 'offline' }
        })
      }
    } else if (fnc === 'btn_positive') {
      if (dlgData.step === 'start') {
        dlgData.step = 'working'
        this.onConvertOfflineAudit('start_import')
      } else if (dlgData.step === 'completed') {
        if (dlgData.auditID > 0) {
          this.loadEntry(dlgData.auditID, 'online')
        }
      }
    }
  }

  /**
   * Löscht ein Offline-Audit und leitet bei Erfolg auf die Liste
   * der Offline-Audits weiter, ansonsten wird eine Fehlermeldung angezeigt
   */
  public async onRemoveOfflineAudit(): Promise<void> {
    const payload: NotificationPayload = {
      text: this.$t(
        'auditView.notification.error.offlineAuditDeleteFailed'
      ).toString(),
      type: 'error',
      data: {}
    }

    if (!this.allowRemoveOfflineAudit || this.entryID <= 0) {
      this.dlgRemoveOfflineAudit.display = false
      this.toggleToast(payload)
    } else {
      await removeOfflineAudit(this.basicSelector, this.entryID).then(
        (): void => {
          this.$router.push({
            name: 'audit_list',
            params: {
              content: this.usedContent
            }
          })
        },
        (): void => {
          this.dlgRemoveOfflineAudit.display = false
          this.toggleToast(payload)
        }
      )
    }
  }
}
