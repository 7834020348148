import { Route, Location } from 'vue-router'

/**
 * Diese Funktion wird von der View [[AuditView]] verwendet. Es werden die
 * Routenparameter `content` und `id` auf ihre Gültigkeit geprüft. Wenn der
 * Parameter `id` nicht einer ID entspricht, wird zur Übersicht der Audits
 * weitergeleitet. Sollte der Paramater `content` ungültig sein, wird zur
 * Index-Seite weitergeleitet.
 *
 * @param to - Routen-Information von der Zielseite.
 * @param from - Routen-Information von der Quellseite.
 * @param next - Funktion zum wechseln/laden der nächste Seite.
 */
function beforeRoute(
  to: Route,
  from: Route,
  next: (to?: string | Location) => void
): void {
  const paramsContent: string = to.params.content

  if (paramsContent !== 'online' && paramsContent !== 'offline') {
    next('/')
  } else if (to.params.id === 'new') {
    if (paramsContent === 'online') {
      next({ name: 'audit_list', params: { content: paramsContent } })
    } else {
      next()
    }
  } else {
    const entryID = parseInt(to.params.id, 10)

    if (isNaN(entryID) || entryID <= 0) {
      next({ name: 'audit_list', params: { content: paramsContent } })
    } else {
      next()
    }
  }
}

/**
 * Funktion beforeRouteEnter der View [[AuditView]].
 */
export const beforeRouteEnter = beforeRoute

/**
 * Funktion beforeRouteUpdate der View [[AuditView]].
 */
export const beforeRouteUpdate = beforeRoute
